import React from 'react';
import AboutSection from '../components/about-section';
import Footer from '../components/footer';
import GallerySection from '../components/gallery-section';
import Header from '../components/header';
import Hero from '../components/hero';
import Layout from '../components/layout';
import SEO from '../components/seo';
import WhyBuySection from '../components/why-buy-section';
import bgKitchen from '../images/bg-kitchen.jpg';
// Data imports
import theme from '../styles/theme';
import { data as HomePageData } from './index.js';

// Create centralized data object to use for template
const data = {
  ...HomePageData,
  hero: {
    heroImage: bgKitchen,
    headline: 'Thank You!',
    subHeadline: "You'll be contacted shortly by one of our representatives.",
  },
};

const ThankYouPage = () => {
  return (
    <Layout theme={theme}>
      <SEO title="Community Scout" />
      <Header
        siteTitle={data.siteTitle}
        phoneNumber={data.phoneNumber}
        logo={data.siteLogo}
      />
      <Hero
        headline={data.hero.headline}
        subHeadline={data.hero.subHeadline}
        heroImage={data.hero.heroImage}
      />
      <AboutSection {...data.about} />
      <WhyBuySection {...data.whyBuy} />
      <GallerySection {...data.gallery} />
      <Footer />
    </Layout>
  );
};

export default ThankYouPage;
